// appointmentDateTimeStyles.js

const styles = {
  containerStyle: {
    width: '100%',
    height: 100,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px #EBEBEB solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'flex',
  },

  headerStyle: {
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'inline-flex',
  },

  dateTimeContainerStyle: {
    flex: '1 1 0',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'inline-flex',
  },

  dateTimeHeaderStyle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    display: 'inline-flex',
  },

  iconStyle: {
    width: 24,
    height: 24,
    position: 'relative',
  },

  iconImageStyle: {
    height: 25,
    width: 25,
    marginRight: '0.5rem',
  },

  dateStyle: {
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },

  timeContainerStyle: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },

  timeHeaderStyle: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'inline-flex',
  },

  timeInfoStyle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    display: 'flex',
  },

  timeTextStyle: {
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },

  durationTextStyle: {
    color: '#717171',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },

  changeButtonStyle: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    display: 'flex',
  },

  changeTextStyle: {
    color: '#0068DE',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: '600',
    textDecoration: 'underline',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
}

export default styles
