import { useContext, useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonCol, IonRow, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonLabel, IonList, IonItem, IonAvatar, IonIcon } from '@ionic/react';
import Card from './card';
import { AuthContext } from '../auth'

import AppointmentCard from '../commonComponents/appointmentCard';
import Header from '../commonComponents/header';
import CreateAccountCard from '../commonComponents/createAccountCard';
import BookButton from '../commonComponents/bookButton';

export default function Appointments({ appointments, openLoginModal, openRegisterModal, openScheduleModal }) {
  const { firebaseUser } = useContext(AuthContext);

  return (
    <IonPage>
      <Header title="Appointments" />
      <IonContent fullscreen={true}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: 600 }}>
            {!firebaseUser ?
              <>
                <BookButton openScheduleModal={openScheduleModal}/>
                <CreateAccountCard openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} />
              </>
              :
              <>
                <BookButton openScheduleModal={openScheduleModal}/>
                {appointments && appointments.map((a) => {
                  return <AppointmentCard appointment={a} key={a._id} />
                })}
              </>
            }

          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};




