export default function ServicesProductsSummary({ mode, setStep, appointment, hideTotal, getAppointment, subscription }) {
  return (
    <>
      <div
        style={{
          alignSelf: 'stretch',
          // height: 320,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          display: 'flex',
        }}
      >
        <div style={{ width: '100%', height: 48, justifyContent: 'flex-start', alignItems: 'center', gap: 36, display: 'inline-flex' }}>
          <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
            <div style={{ color: '#222222', fontSize: 22, fontFamily: 'Inter', fontWeight: '600', lineHeight: '32px', wordWrap: 'break-word' }}>
              {appointment?.status == 'Complete' ? '	Receipt' : 'Scheduled Services'}{' '}
            </div>
          </div>
          {['Complete'].includes(appointment?.status) ? null : (
            <div
              style={{
                flex: '1 1 0',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                gap: 10,
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderRadius: 8,
                  overflow: 'hidden',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 8,
                  display: 'inline-flex',
                }}
              >
                <div
                  onClick={() => {
                    setStep('service')
                  }}
                  style={{
                    color: '#0068DE',
                    fontSize: 16,
                    fontFamily: 'Figtree',
                    fontWeight: '600',
                    textDecoration: 'underline',
                    lineHeight: '24px',
                    wordWrap: 'break-word',
                  }}
                >
                  Change
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            alignSelf: 'stretch',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 12,
            paddingBottom: 12,
            background: '#F5F5F6',
            borderRadius: 12,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 8,
            display: 'flex',
          }}
        >
          <div
            style={{
              alignSelf: 'stretch',
              paddingBottom: 12,
              borderBottom: hideTotal ? '' : '1px #DADADA solid',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            {appointment?.services?.map((item, index) => (
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingTop: 8,
                  paddingBottom: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 219,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 6,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      height: 24,
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      gap: 10,
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: 236,
                        flex: '1 1 0',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 1 0',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    gap: 10,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '500',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    ${item.price}
                  </div>
                </div>
              </div>
            ))}
            {appointment?.products?.map((item, index) => (
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingTop: 8,
                  paddingBottom: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 219,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 6,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      height: 24,
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      gap: 10,
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: 236,
                        flex: '1 1 0',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 1 0',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    gap: 10,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '500',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {`$${parseFloat(item.price)?.toFixed(2)}`}{' '}
                  </div>
                </div>
              </div>
            ))}
            {appointment?.payment?.discounts?.map((item, index) => (
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingTop: 8,
                  paddingBottom: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 219,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 6,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      height: 24,
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      gap: 10,
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: 236,
                        flex: '1 1 0',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 1 0',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    gap: 10,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '500',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {`-$${item?.amount?.toFixed(2)}`}
                  </div>
                </div>
              </div>
            ))}
            {appointment?.payment?.taxes !== 0 || ['Complete'].includes(appointment?.status) ? (
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingTop: 8,
                  paddingBottom: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 219,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 6,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      height: 24,
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      gap: 10,
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: 236,
                        flex: '1 1 0',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      Taxes
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 1 0',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    gap: 10,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '500',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {`$${appointment?.payment?.taxes?.toFixed(2)}`}
                  </div>
                </div>
              </div>
            ) : null}
            {['Complete'].includes(appointment?.status) && appointment?.payment?.tipAmount > 0 ? (
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingTop: 8,
                  paddingBottom: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 219,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 6,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      height: 24,
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      gap: 10,
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: 236,
                        flex: '1 1 0',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {appointment?.payment?.cashTipAmount ? 'Tip (Credit Card)' : 'Tip'}{' '}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 1 0',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    gap: 10,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '500',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {`$${appointment?.payment?.tipAmount?.toFixed(2) || '-'}`}
                  </div>
                </div>
              </div>
            ) : null}
            {['Complete'].includes(appointment?.status) && appointment?.payment?.cashTipAmount > 0 ? (
              <div
                style={{
                  alignSelf: 'stretch',
                  paddingTop: 8,
                  paddingBottom: 8,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 219,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 6,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'stretch',
                      height: 24,
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      gap: 10,
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: 236,
                        flex: '1 1 0',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      Tip (Cash){' '}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: '1 1 0',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    gap: 10,
                    display: 'inline-flex',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '500',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {`$${appointment?.payment?.cashTipAmount?.toFixed(2) || '-'}`}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {hideTotal ? null : (
            <div
              style={{
                alignSelf: 'stretch',
                height: 44,
                paddingTop: 8,
                paddingBottom: 8,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                gap: 6,
                display: 'flex',
              }}
            >
              <div
                style={{
                  alignSelf: 'stretch',
                  borderRadius: 12,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  gap: 4,
                  display: 'inline-flex',
                }}
              >
                <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', display: 'inline-flex' }}>
                  <div style={{ alignSelf: 'stretch', justifyContent: 'flex-end', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                    <div
                      style={{
                        textAlign: 'right',
                        color: '#222222',
                        fontSize: 18,
                        fontFamily: 'Figtree',
                        fontWeight: '700',
                        lineHeight: '28px',
                        wordWrap: 'break-word',
                      }}
                    >
                      Total:
                    </div>
                  </div>
                </div>
                <div style={{ justifyContent: 'flex-end', alignItems: 'center', gap: 10, display: 'flex' }}>
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#222222',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '700',
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {`$${appointment?.payment?.totalPrice?.toFixed(2)}`}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
