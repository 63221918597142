import { getIdToken, signOut } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { auth } from './firebase'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState('loading')
  const [userData, setUserData] = useState('loading')
  const [lastRefreshTime, setLastRefreshTime] = useState(Date.now())
  const [locationData, setLocationData] = useState({})
  const [businessData, setBusinessData] = useState({})
  const [locations, setLocations] = useState([])
  useEffect(() => {
    auth.onAuthStateChanged(setFirebase)
  }, []) // use auth instead of getAuth()

  useEffect(() => {
    getBusiness()
    getUserData()
    getLocation()
  }, [firebaseUser])

  async function setFirebase(fb) {
    setFirebaseUser(fb)
    axios.defaults.headers.common['Authorization'] = fb ? `Bearer ${fb.accessToken}` : null
  }
  const getLocation = async () => {
    let response = await axios.get(`/location?id=${process.env.REACT_APP_LOCATION_ID}`)
    setLocationData(response?.data || {})
  }
  async function getLocations() {
    let d = await axios.get(`/location/publicGetListByBusinessId?businessId=${process.env.REACT_APP_BUSINESS_ID}`)
    setLocations(d.data)
  }
  const getBusiness = async () => {
    try {
      //settin default business id in headers to null
      axios.defaults.headers.common['prop-businessId'] = process.env.REACT_APP_BUSINESS_ID
      //get all the lists of business
      const response = await axios.get(`/business/domain`)

      console.log(response.data)

      if (response?.data?.firebaseTenantId === 'clubcuts') {
        //do nothing
      } else if (response?.data?.firebaseTenantId) {
        auth.tenantId = response?.data?.firebaseTenantId
      } else {
        throw new Error('No Tenant ID')
      }
      getLocations()
      setBusinessData(response?.data || null)
    } catch (error) {
      setBusinessData(null)
    }
  }

  async function getUserData() {
    if (firebaseUser && firebaseUser.uid) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${firebaseUser.accessToken}`
      let response = await axios.get('/customer')
      setUserData(response.data)
    } else {
      setUserData(null)
    }
  }

  async function refreshToken() {
    const currentTime = Date.now()
    const timeSinceLastRefresh = currentTime - lastRefreshTime

    if (firebaseUser && timeSinceLastRefresh >= 30 * 60 * 1000) {
      const newToken = await getIdToken(firebaseUser, true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      setLastRefreshTime(currentTime)
    }
  }

  useEffect(() => {
    const tokenRefreshInterval = setInterval(refreshToken, 2 * 60 * 1000)

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshToken()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(tokenRefreshInterval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [firebaseUser])

  function logout() {
    signOut(auth) // use auth instead of getAuth()
    setFirebaseUser(null)
    setUserData(null)
    axios.defaults.headers.common['Authorization'] = null

    // Clear refid and refidExpiration from session storage
    sessionStorage.removeItem('refid')
    sessionStorage.removeItem('refidExpiration')
  }

  return (
    <AuthContext.Provider
      value={{
        firebaseUser,
        userData,
        getUserData,
        logout,
        locationData,
        getLocation,
        businessData,
        locations,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
