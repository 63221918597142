import { useState, useEffect, useContext } from 'react'
import { Grid, Box, Fade } from '@mui/material'
import axios from 'axios'
import StepPickBarber from './stepPickBarber'
import StepPickLocation from './stepPickLocation'
import StepPickDateTime from './stepPickDateTime'
import StepPickService from './stepPickService'
import StepSuccess from './stepSuccess'
import StepReviewOrder from './reviewOrder'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonSpinner, IonTitle, IonToolbar } from '@ionic/react'
import StepLoginOrRegister from './stepLoginOrRegister'
import LeftIcon from '@mui/icons-material/ChevronLeft'
import HeaderComponent from './headerComponent'
import { AuthContext } from '../auth'
import { calculatePayment } from '../sharedComponents/paymentCalculation'
import StandardContainer from '../commonComponents/standardContainer'
import { trackScheduling } from '../analytics'
import StepCancellation from './StepCancellation'
import { closeOutline } from 'ionicons/icons'

const HEADER_TEXT = {
  service: 'Pick a Service',
  dateTime: 'Pick a Time',
  barber: 'Pick a Professional',
  loginOrRegister: 'Login or Register',
  summary: 'Review Appointment',
  success: 'Thanks for Booking!',
  location: 'Pick a Location',
}

export default function StepController({ isOpen, closeModal, getAppointments, isWalkin, setSubscriptionReferrer }) {
  let businessId = process.env.REACT_APP_BUSINESS_ID
  let locationId = process.env.REACT_APP_LOCATION_ID
  const { userData, firebaseUser, locations } = useContext(AuthContext)
  console.log(locations, 'locations')
  const [step, setStep] = useState(0)
  const [steps, setSteps] = useState()
  const [location, setLocation] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [stepBackData, setStepBackData] = useState(null)
  const [trackedSteps, setTrackedSteps] = useState([])

  useEffect(() => {
    if (userData && userData != 'loading') {
      setCustomer({ _id: userData._id })
    }
  }, [userData])

  useEffect(() => {
    if (isWalkin) {
      let steps = []
      if (locations.length > 1) {
        steps.push('location')
      }
      steps.push(...['service', 'summary', 'success'])
      setSteps(steps)
    } else {
      let baseSteps = []
      if (locations.length > 1) {
        baseSteps.push('location')
      }
      baseSteps.push(...['barber', 'service', 'dateTime'])
      baseSteps.push(...['cancellationPolicy', 'summary', 'success'])
      setSteps(baseSteps)
    }
  }, [isOpen, locations])

  useEffect(() => {
    if (locationId && isOpen) {
      getLocation()
    }
  }, [isOpen])

  async function getLocation() {
    let response = await axios.get(`/location/publicGet?locationId=${locationId}`)
    setLocation(response.data)
  }

  function trackStep(stepName, step, data) {
    trackScheduling(stepName, step, data, trackedSteps.includes(stepName) ? true : false)
    setTrackedSteps([...trackedSteps, ...[stepName]])
  }

  function stepNext() {
    if (step + 1 >= steps.length) {
      closeModal()
    } else {
      setStep(step + 1)
    }
  }

  function stepBack() {
    if (step == 0) {
      closeModal()
    } else {
      if (stepBackData) {
        setCoreAppointment(stepBackData)
      }

      setStep(step - 1)
    }
  }
  const handleChangeStep = (stepName) => {
    const index = steps.indexOf(stepName)
    setStep(index)
  }

  const [coreAppointment, setCoreAppointment] = useState({
    businessId: businessId,
    barber: null,
    anyProfessional: isWalkin ? true : false,
    isWalkin: isWalkin ? true : false,
    services: [],
    dateTime: null,
    payment: {
      subTotalPrice: 0,
      totalPrice: 0,
      tipAmount: 0,
      tipPercentage: 0,
      taxes: 0,
      taxRate: 0,
      productsTotal: 0,
      servicesTotal: 0,
      totalDiscount: 0,
    },
    durationMin: null,
    wasLoggedInAtStart: userData?._id ? true : false,
  })

  function handleCancel() {
    closeModal()
  }
  function updateScheduleData(key, value) {
    setStepBackData(null)
    let updatedData = { ...coreAppointment }
    updatedData[key] = value

    console.log(key, value)
    console.log(JSON.stringify(value))

    if (key == 'dateTime') {
      updatedData.barber = value.barber
    }

    if (key == 'dateTimeAnyProfessional') {
      updatedData.barber = value.barber
      updatedData['requestedBarber'] = null
      updatedData.anyProfessional = true
      delete value.barber
      updatedData['dateTime'] = value
      setStepBackData(coreAppointment)
    }

    if (key == 'barber' && value == 'Anyone') {
      updatedData[key] = null
      updatedData['requestedBarber'] = null
      updatedData.anyProfessional = true
    } else if (key == 'barber') {
      updatedData['requestedBarber'] = value
      updatedData.anyProfessional = false
    }

    //if Schedule Update
    if (key == 'services') {
      let duration = 0
      for (let s of updatedData?.services) {
        duration += s.durationMin
      }
      updatedData.durationMin = duration

      updatedData.payment = calculatePayment(updatedData?.services, updatedData?.products, userData?.subscription?.subscriptionPackageId, null, null, null)
    }

    console.log(updatedData)

    setCoreAppointment(updatedData)
  }

  function track() {}
  console.log(coreAppointment, 'coreAppointment')
  let scheduleData = { ...coreAppointment, ...{ customer } }
  if (locations.length <= 1) {
    scheduleData.location = location
  }

  let stepComponent = null
  let stepName = !location ? 'loading' : (steps && steps[step]) || null
  let fadeDuration = 1000
  console.log(scheduleData, 'scheduleData')

  if (stepName == 'barber') {
    stepComponent = (
      <StepPickBarber
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'location') {
    stepComponent = (
      <div>
        <Fade id={'new'} in={true} timeout={{ enter: fadeDuration }}>
          <div>
            <StepPickLocation
              locations={locations}
              businessId={businessId}
              scheduleData={scheduleData}
              updateScheduleData={updateScheduleData}
              step={step}
              stepBack={stepBack}
              stepNext={stepNext}
              totalSteps={(steps && steps.length) || 0}
              trackStep={trackStep}
            />
          </div>
        </Fade>
      </div>
    )
  } else if (stepName == 'service') {
    stepComponent = (
      <StepPickService
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        userSubscription={userData?.subscription}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'dateTime') {
    stepComponent = (
      <StepPickDateTime
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'cancellationPolicy') {
    stepComponent = (
      <StepCancellation
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'summary') {
    if (!customer) {
      stepComponent = (
        <span>
          <Fade in={true} timeout={{ enter: fadeDuration }}>
            <div>
              <StepLoginOrRegister
                businessId={businessId}
                scheduleData={scheduleData}
                updateScheduleData={updateScheduleData}
                step={step}
                stepBack={stepBack}
                stepNext={stepNext}
                totalSteps={(steps && steps.length) || 0}
                trackStep={trackStep}
              />
            </div>
          </Fade>
        </span>
      )
    } else {
      stepComponent = (
        <div>
          <Fade in={true} timeout={{ enter: fadeDuration }}>
            <div>
              <StepReviewOrder
                setStep={handleChangeStep}
                isWalkin={isWalkin}
                userSubscription={userData?.subscription}
                businessId={businessId}
                scheduleData={scheduleData}
                updateScheduleData={updateScheduleData}
                step={step}
                stepBack={stepBack}
                stepNext={stepNext}
                totalSteps={(steps && steps.length) || 0}
                trackStep={trackStep}
              />
            </div>
          </Fade>
        </div>
      )
    }
  } else if (stepName == 'success') {
    stepComponent = (
      <Fade in={true} timeout={{ enter: fadeDuration }}>
        <div>
          <StepSuccess
            businessId={businessId}
            isSubscribed={userData?.isSubscribed}
            scheduleData={scheduleData}
            updateScheduleData={updateScheduleData}
            step={step}
            stepBack={stepBack}
            stepNext={stepNext}
            totalSteps={(steps && steps.length) || 0}
            getAppointments={getAppointments}
            isWalkin={isWalkin}
            trackStep={trackStep}
            setSubscriptionReferrer={setSubscriptionReferrer}
          />
        </div>
      </Fade>
    )
  } else {
    stepComponent = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <IonSpinner name='dots' />
      </div>
    )
  }

  return (
    <>
      {stepName == 'success' ? null : (
        <IonHeader style={{ paddingTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white' }}>
          {stepName == 'success' || step == 0 ? null : (
            <IonButtons slot='start'>
              <IonButton onClick={stepBack} style={{ color: 'black' }}>
                <LeftIcon fontSize='large' />
              </IonButton>
            </IonButtons>
          )}

          <IonButtons slot='end' style={{ marginRight: 20, color: 'black' }}>
            <IonIcon onClick={handleCancel} style={{ height: 30, width: 30 }} icon={closeOutline} />
            {/* <IonButton onClick={handleCancel}>Cancel</IonButton> */}
          </IonButtons>
        </IonHeader>
      )}
      <IonContent fullscreen={true} className='whiteBackground'>
        <StandardContainer padding={10}>
          <HeaderComponent handleBack={stepBack} progress={{ total: (steps && steps.length) || 1, step: (steps && step + 1) || 0 }} />
          {stepComponent}
        </StandardContainer>
      </IonContent>
    </>
  )
}
