import { useState, useEffect } from 'react'
import { Button, Grid, Fade } from '@mui/material'
import axios from 'axios'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { getFormattedMediumDate, getFormattedMediumDateWithoutTimeZone } from '../utils'
import { IonIcon, IonLabel, IonSpinner } from '@ionic/react'
import StandardCenterCenter from '../commonComponents/StandardCenterContainer'
import { colors } from '../theme/colors'
import './Dates.css'
import {
  containerStyle,
  headerStyle,
  headerTextStyle,
  datePickerContainerStyle,
  datePickerStyle,
  datePickerInnerStyle,
  weekDatesContainerStyle,
  timeSlotContainerStyle,
  subHeaderStyle,
  availableTimesContainerStyle,
  availableTimesInnerContainerStyle,
  timeSlotWrapperStyle,
  timeSlotStyle,
  timeIconContainerStyle,
  timeTextStyle,
  headerContainerStyle,
} from '../Scheduler/Styles/PickDateTimeStyles'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment-timezone'
import { chevronBack, chevronForward, closeOutline, timeOutline } from 'ionicons/icons'

const styles = {
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
const formatDateToISO = (date) => {
  const pad = (n) => (n < 10 ? '0' + n : n)
  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = pad(date.getSeconds())
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

export default function StepPickDateTime({ businessId, updateScheduleData, step, stepNext, isWalkin, scheduleData, trackStep }) {
  console.log('Render StepPickDateTime')
  const [dateAndTimeData, setDateAndTimeData] = useState()
  const [anyProfessionalDateAndTimeData, setAnyProfessionalDateAndTimeData] = useState({ loading: false, data: null })
  const [value, setValue] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState(moment(value))
  const [view, setView] = useState('week') // State to toggle between week and month view
  const [date, setDate] = useState(formatDateToISO(new Date()))
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const handlePrevWeek = () => {
    const prevWeek = moment(value).subtract(7, 'days')
    setValue(getFormattedMediumDateWithoutTimeZone(prevWeek.toDate()))
  }
  const handleNextWeek = () => {
    const nextWeek = moment(value).add(7, 'days')
    setValue(getFormattedMediumDateWithoutTimeZone(nextWeek.toDate()))
  }
  function updateTime(dateValue) {
    setValue(dateValue)
    setIsLoading(true)
    setDateAndTimeData()
    setAnyProfessionalDateAndTimeData({ loading: true, data: null })
  }

  useEffect(() => {
    trackStep('PickDateTime', step, scheduleData)
  }, [])

  useEffect(() => {
    getAvailability()
    getAnyProfessionalAvaialbility()
  }, [value])

  const handleClick = (day) => {
    if (!isLoading) {
      updateTime(day.toDate())
    }
  }

  const getAvailability = async () => {
    let services = scheduleData?.services.map((service) => service._id)
    if (!isWalkin) {
      let response = await axios.get(
        `/appointment/getAvailable?businessId=${businessId}&locationId=${scheduleData?.location?._id || null}&barberId=${
          scheduleData?.anyProfessional ? 'undefined' : scheduleData?.barber?._id
        }&durationMin=${scheduleData?.durationMin}&customerId=${scheduleData?.customer?._id}&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${
          scheduleData?.anyProfessional
        }&services=${services}`
      )
      setDateAndTimeData(response.data)
      setIsLoading(false)
    }
  }

  const getAnyProfessionalAvaialbility = async () => {
    let services = scheduleData?.services.map((service) => service._id)

    if (!isWalkin && !scheduleData?.anyProfessional) {
      let response2 = await axios.get(
        `/appointment/getAvailable?businessId=${businessId}&locationId=${scheduleData?.location?._id || null}&barberId=${'undefined'}&durationMin=${
          scheduleData?.durationMin
        }&customerId=${scheduleData?.customer?._id}&selectedDate=${getFormattedMediumDate(value)}&anyProfessional=${true}&services=${services}`
      )
      setAnyProfessionalDateAndTimeData({ loading: false, data: response2.data })
    } else {
      setAnyProfessionalDateAndTimeData({ loading: false, data: null })
    }
  }

  function handleTimeClick(data, isAnyProfessional) {
    if (isAnyProfessional) {
      updateScheduleData('dateTimeAnyProfessional', data)
    } else {
      updateScheduleData('dateTime', data)
    }

    stepNext()
  }
  const handleMonthChange = (date) => {
    setView('week')

    setStartDate(moment(date))
    setDate(formatDateToISO(new Date(date)))
    const dateValue = new Date(value).getDate()
    setValue(moment(date).startOf('month').date(dateValue).toDate())
  }

  return (
    <>
      <Fade in={true}>
        <div>
          {/* <HeaderComponent title={'Pick a time'} handleBack={stepBack} progress={{ total: totalSteps, step: step + 1 }} /> */}
          <DateTimeBody
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            handleClick={handleClick}
            handlePrevWeek={handlePrevWeek}
            handleNextWeek={handleNextWeek}
            handleMonthChange={handleMonthChange}
            startDate={startDate}
            value={value}
            setValue={updateTime}
            dateAndTimeData={dateAndTimeData}
            handleTimeClick={handleTimeClick}
            isLoading={isLoading}
            anyProfessionalDateAndTimeData={anyProfessionalDateAndTimeData}
            barber={scheduleData?.barber}
          />
        </div>
      </Fade>
    </>
  )
}

function DateTimeBody({
  selectedPeriod,
  setSelectedPeriod,
  handleClick,
  handlePrevWeek,
  handleNextWeek,
  handleMonthChange,
  startDate,
  value,
  setValue,
  dateAndTimeData,
  handleTimeClick,
  isLoading,
  anyProfessionalDateAndTimeData,
  barber,
}) {
  function handlePeriodClick(period) {
    setSelectedPeriod(period)
  }
  const filterAvailableTimes = (times) => {
    switch (selectedPeriod) {
      case 'Morning':
        return times.filter((slot) => moment(slot.startTime).hour() < 12)
      case 'Afternoon':
        return times.filter((slot) => moment(slot.startTime).hour() >= 12 && moment(slot.startTime).hour() < 16)
      case 'Evening':
        return times.filter((slot) => moment(slot.startTime).hour() >= 16)
      default:
        return times
    }
  }
  const startOfWeek = moment(value).startOf('week') // Start of the current selected week
  const endOfPrevWeek = moment(startOfWeek).subtract(1, 'day').endOf('day') // End of previous week
  const startOfNextWeek = moment(startOfWeek).add(1, 'week') // Start of next week
  const weekDays = [
    moment(endOfPrevWeek), // Previous week's last day
    ...Array.from({ length: 7 }, (_, i) => moment(startOfWeek).add(i, 'days')), // Current week
    moment(startOfNextWeek), // Next week's first day
  ]
  let subHeader = (
    <div style={styles.loadingBox}>
      <IonSpinner name='dots' />
    </div>
  )

  if (!isLoading) {
    subHeader = dateAndTimeData?.outOfBounds == true ? 'SCHEDULE NOT AVAILABLE YET' : dateAndTimeData?.locationOpen ? 'OPEN' : 'CLOSED'
  }

  let mainDateTimeMessage
  if (dateAndTimeData?.availableTimes?.length == 0) {
    mainDateTimeMessage = `No available appointments for ${
      barber?.firstName || 'any professional'
    }. Please check another day or check below for times from another professional.`
  }
  console.log(value, 'valueeee')
  const isTodayOrLater = checkIsTodayOrLater(value) // Check if the date is today or later
  function checkIsTodayOrLater(date) {
    const today = new Date()
    // Set the hours to 0, 0, 0, 0 to compare only the date part
    today.setHours(0, 0, 0, 0)
    const chosenDate = new Date(date)
    const selectedDate = new Date(chosenDate.getFullYear(), chosenDate.getMonth(), chosenDate.getDate())
    return selectedDate >= today
  }
  return (
    <>
      <div style={containerStyle}>
        <div style={headerStyle}>
          <div style={headerTextStyle}>Select Date & Time</div>
        </div>
        <div style={datePickerContainerStyle}>
          <div style={datePickerStyle}>
            <div style={datePickerInnerStyle}>
              <div style={headerContainerStyle}>
                <div style={headerTextStyle}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      slotProps={{
                        textField: ({ position }) => ({
                          sx: {
                            maxWidth: '8rem', // Set the maximum width
                            '& .MuiInputBase-root': {
                              borderColor: 'white',
                              '&:before': {
                                borderBottomColor: 'white',
                              },
                              '&:after': {
                                borderBottomColor: 'white',
                              },
                              '&:hover:not(.Mui-disabled):before': {
                                borderBottomColor: 'white',
                              },
                              '&:hover:not(.Mui-disabled):after': {
                                borderBottomColor: 'white',
                              },
                            },

                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },
                            },
                          },
                          focused: true,
                        }),
                      }}
                      size='small'
                      views={['month']}
                      minDate={moment().subtract(10, 'years')}
                      maxDate={moment().add(10, 'years')}
                      value={startDate}
                      onChange={(newValue) => {
                        handleMonthChange(newValue)
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='week-dates-container' style={{ margin: '0 auto' }}>
        <IonIcon
          style={{ marginLeft: '0.5rem' }}
          className='arrow-icon'
          icon={chevronBack}
          onClick={() => {
            const newSelectedDate = new Date(value)
            newSelectedDate.setDate(newSelectedDate.getDate() - 7)
            setValue(newSelectedDate)
            handlePrevWeek(value)
          }}
        />
        <div style={weekDatesContainerStyle}>
          {weekDays.map((day, index) => {
            const isTodayOrLater = day.isSameOrAfter(new Date(), 'day') // Check if the date is today or later
            const isSelectedDay = day.isSame(value, 'day')

            return (
              <div
                onClick={() => isTodayOrLater && handleClick(day)}
                key={index}
                className={`day-container ${index === 0 || index === weekDays.length - 1 ? 'grey-day' : ''}`}
                style={{
                  backgroundColor: isSelectedDay ? '#3880ff' : day.isSame(new Date(), 'day') ? '#d7d7d7' : '',
                  cursor: isTodayOrLater ? 'pointer' : 'not-allowed', // Change cursor to not-allowed if the date is in the past
                }}
              >
                <IonLabel
                  style={{
                    flex: '1 1 0',
                    textAlign: 'center',
                    fontSize: 14,
                    fontFamily: 'Figtree',
                    fontWeight: '600',
                    lineHeight: '20px',
                    wordWrap: 'break-word',
                    color: isTodayOrLater ? (isSelectedDay ? 'white' : '') : '#A9A9A9', // Change text color if the date is in the past
                  }}
                  className={`date-label ${isSelectedDay ? 'active-weekday-label' : 'weekday-label'}`}
                >
                  {day.format('ddd').slice(0, 2)}
                </IonLabel>
                <button
                  className={`circle-button${isSelectedDay ? ' active' : ''}`}
                  disabled={!isTodayOrLater} // Disable the button if the date is in the past
                  style={{
                    pointerEvents: isTodayOrLater ? 'auto' : 'none', // Prevent click events if the date is in the past
                  }}
                >
                  <div
                    style={{
                      width: 44,
                      textAlign: 'center',
                      fontSize: 18,
                      fontFamily: 'Figtree',
                      fontWeight: '700',
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                      color: isTodayOrLater ? (isSelectedDay ? '' : '#525252') : '#A9A9A9', // Change text color if the date is in the past
                    }}
                    className='date'
                  >
                    {day.format('D')}
                  </div>
                </button>
              </div>
            )
          })}
        </div>
        <IonIcon
          style={{ marginRight: '0.5rem' }}
          className='arrow-icon'
          icon={chevronForward}
          onClick={() => {
            const newSelectedDate = new Date(value)
            newSelectedDate.setDate(newSelectedDate.getDate() + 7)
            setValue(newSelectedDate)
            handleNextWeek(value)
          }}
        />
      </div>
      {isTodayOrLater && (
        <>
          <div style={timeSlotContainerStyle}>
            <div
              style={{
                alignSelf: 'stretch',
                flexDirection: 'column',
                justifyContent: subHeader == 'CLOSED' ? 'center' : 'flex-start',
                alignItems: subHeader == 'CLOSED' ? 'center' : 'flex-start',
                gap: 2,
                display: 'flex',
              }}
            >
              <div style={subHeaderStyle}>{subHeader}</div>
            </div>
          </div>

          {subHeader === 'CLOSED' ? (
            <StandardCenterCenter>
              <Closed />
            </StandardCenterCenter>
          ) : null}
          {subHeader == 'OPEN'
            ? dateAndTimeData?.availableTimes?.length > 0 && (
                <div style={availableTimesContainerStyle}>
                  <h6
                    style={{
                      padding: '12px 25px',
                      alignSelf: 'start',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'start',
                      fontFamily: 'FigTree',
                    }}
                  >
                    Availability for {barber?.firstName || 'Any Professional'}
                  </h6>
                  <MainDateMessage message={mainDateTimeMessage} />

                  <div style={availableTimesInnerContainerStyle}>
                    <div style={timeSlotWrapperStyle}>
                      {['Morning', 'Afternoon', 'Evening'].map((period) => (
                        <div
                          key={period}
                          style={{
                            ...timeSlotStyle,
                            color: selectedPeriod === period ? '#0068DE' : '#717171', // Blue for selected, gray for others
                            border: selectedPeriod === period ? '1px #0068DE solid' : '1px #DADADA solid', // Blue border for selected, gray for others
                          }}
                          onClick={() => handlePeriodClick(selectedPeriod === period ? '' : period)}
                        >
                          <div style={timeIconContainerStyle}>
                            <IonIcon icon={timeOutline} />
                          </div>
                          <div style={{ ...timeTextStyle, color: selectedPeriod === period ? '#0068DE' : '#717171' }}>{period}</div>
                          {selectedPeriod === period && <IonIcon icon={closeOutline} />} {/* Show icon only for selected period */}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: 'stretch',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: 24,
                      display: 'flex',
                    }}
                  >
                    <div style={{ flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex' }}>
                      {filterAvailableTimes(dateAndTimeData.availableTimes)?.map((timeSlot) => {
                        return (
                          <TimeSlotButton available={timeSlot.available} timeSlot={timeSlot} handleTimeClick={handleTimeClick} key={timeSlot.startTimeText} />
                        )
                      }) || null}
                    </div>
                  </div>
                </div>
              )
            : null}
          {anyProfessionalDateAndTimeData?.data && anyProfessionalDateAndTimeData?.data?.availableTimes?.length > 0 ? (
            <div style={availableTimesContainerStyle}>
              <h6
                style={{ padding: '12px 25px', alignSelf: 'start', display: 'flex', justifyContent: 'flex-start', alignItems: 'start', fontFamily: 'FigTree' }}
              >
                Availability for Another Professional
              </h6>

              <div style={availableTimesInnerContainerStyle}>
                <div style={timeSlotWrapperStyle}>
                  {['Morning', 'Afternoon', 'Evening'].map((period) => (
                    <div
                      key={period}
                      style={{
                        ...timeSlotStyle,
                        color: selectedPeriod === period ? '#0068DE' : '#717171', // Blue for selected, gray for others
                        border: selectedPeriod === period ? '1px #0068DE solid' : '1px #DADADA solid', // Blue border for selected, gray for others
                      }}
                      onClick={() => handlePeriodClick(selectedPeriod === period ? '' : period)}
                    >
                      <div style={timeIconContainerStyle}>
                        <IonIcon icon={timeOutline} />
                      </div>
                      <div style={{ ...timeTextStyle, color: selectedPeriod === period ? '#0068DE' : '#717171' }}>{period}</div>
                      {selectedPeriod === period && <IonIcon icon={closeOutline} />} {/* Show icon only for selected period */}
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  alignSelf: 'stretch',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 24,
                  display: 'flex',
                }}
              >
                <div style={{ flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex' }}>
                  {filterAvailableTimes(anyProfessionalDateAndTimeData?.data?.availableTimes)?.map((timeSlot) => {
                    return <TimeSlotButton available={timeSlot.available} timeSlot={timeSlot} handleTimeClick={handleTimeClick} key={timeSlot.startTimeText} />
                  }) || null}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}

function TimeSlotButton({ timeSlot, handleTimeClick, available, isAnyProfessional }) {
  const activeBorderColor = available ? '#999999' : null
  const activeColor = available ? 'black' : null
  if (!available) {
    return null
  }
  return (
    <Grid
      item
      style={{ margin: 2, marginTop: 4 }}
      onClick={() => {
        handleTimeClick(timeSlot, isAnyProfessional)
      }}
    >
      <Button
        style={{ width: 95, height: 40, fontSize: 13, fontWeight: '600', borderWidth: 1, borderRadius: 10, borderColor: activeBorderColor, color: activeColor }}
        disabled={!available}
        variant='outlined'
      >
        {timeSlot.startTimeText}
        {/* {timeSlot.barber.firstName} */}
      </Button>
    </Grid>
  )
}

function MainDateMessage({ message }) {
  if (!message) return null
  return (
    <div style={{ backgroundColor: colors.primaryBlue25, border: `2px solid ${colors.primaryBlue}`, borderRadius: 8, padding: 20 }}>
      <StandardCenterCenter>{message}</StandardCenterCenter>
    </div>
  )
}

function Closed() {
  return (
    <div>
      <img src='/assets/closed.png' width={160} height={160} />
    </div>
  )
}
