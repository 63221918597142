export const reviewOrderStyles = {
  // src/styles/YourComponentStyles.js

  containerStyle: {
    alignSelf: 'stretch',
    paddingBottom: 12,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },

  titleStyle: {
    marginTop: 20,
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 32,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '38.4px',
    wordWrap: 'break-word',
  },

  subContainerStyle: {
    alignSelf: 'stretch',
    height: 32,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },

  innerContainerStyle: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },

  innerContentStyle: {
    alignSelf: 'stretch',
    height: 32,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex',
  },

  textContainerStyle: {
    paddingRight: 81,
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
  },

  textStyle: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },

  headingStyle: {
    color: '#222222',
    fontSize: 28,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '33.6px',
    wordWrap: 'break-word',
  },
  // src/styles/ProfessionalInfoCardStyles.js

  professionalcardContainerStyle: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
    display: 'flex',
  },

  professionalheaderContainerStyle: {
    alignSelf: 'stretch',
    height: 20,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },

  professionaltextContainerStyle: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    display: 'inline-flex',
  },

  professionalheaderTextStyle: {
    flex: '1 1 0',
    color: '#525252',
    fontSize: 14,
    fontFamily: 'Figtree',
    fontWeight: '600',
    textTransform: 'uppercase',
    lineHeight: '20px',
    letterSpacing: 1.4,
    wordWrap: 'break-word',
  },
}
