// PickLocationStyles.js

const PickLocationStyles = {
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    marginTop: 20,
    padding: '8px 24px',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
  },
  header: {
    alignSelf: 'stretch',
    paddingBottom: 12,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'flex',
  },
  title: {
    alignSelf: 'stretch',
    color: '#222222',
    fontSize: 32,
    fontFamily: 'Figtree',
    fontWeight: '600',
    lineHeight: '38.4px',
    wordWrap: 'break-word',
  },
  content: {
    alignSelf: 'stretch',
    paddingBottom: 32,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  locationCard: {
    cursor: 'pointer',
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
  },
  locationCardInner: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  locationCardContent: {
    height: 76,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px #EBEBEB solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // gap: 12,
    display: 'flex',
  },
  locationCardData: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    display: 'inline-flex',
  },
  locationCardRow: {
    marginLeft: 10,
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    // gap: 12,
    display: 'inline-flex',
    flexDirection: 'column',
  },
  avatarContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 10,
    display: 'inline-flex',
  },
  avatar: {
    width: 40,
    height: 40,
    position: 'relative',
  },
  locationName: {
    flex: '1 1 0',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '20px',
    wordWrap: 'break-word',
  },
  locationAddress: {
    flex: '1 1 0',
    color: '#222222',
    fontSize: 12,
    fontFamily: 'Figtree',
    fontWeight: '500',
    wordWrap: 'break-word',
  },
}

export default PickLocationStyles
