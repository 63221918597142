// ProfessionalInfoStyles.js
export const styles = {
  containerStyle: {
    width: '100%',
    height: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px #EBEBEB solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
    display: 'inline-flex',
  },

  avatarContainerStyle: {
    width: 40,
    height: 40,
    borderRadius: 9999,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    background: '#E9E9E9',
  },

  nameStyle: {
    flex: '1 1 0',
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '28px',
    wordWrap: 'break-word',
  },

  labelContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    background: '#FF890A',
    borderRadius: 9999,
    border: '1px white solid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
  },

  labelTextStyle: {
    textAlign: 'center',
    color: 'white',
    fontSize: 12,
    fontFamily: 'Figtree',
    fontWeight: '500',
    lineHeight: '18px',
    wordWrap: 'break-word',
  },

  changeButtonStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    display: 'flex',
  },

  changeButtonTextStyle: {
    color: '#0068DE',
    fontSize: 16,
    fontFamily: 'Figtree',
    fontWeight: '600',
    textDecoration: 'underline',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },

  flexContainerStyle: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
    display: 'inline-flex',
  },

  nameContainerStyle: {
    flex: '1 1 0',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'inline-flex',
  },
}
